@media (min-width: 768px) {
  .infrastructure-carousel .carousel-caption {
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

section.infrastructure-section {
  padding: 80px 0;
}

.infrastructure-carousel {
  margin-top: 85px;
}

.infrastructure-section .heading.heading--primary {
  max-width: 700px;
}

.contact-button {
  color: #4a4c70;
}

.contact-button:hover {
  color: #4a4c70;
}

.infrastructure-img {
  height: 650px;
}

.infrastructure-carousel .ombre-externe {
  background-color: white;
  padding: 1rem;
  -moz-box-shadow: 20px 20px 36px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 20px 20px 36px rgba(0, 0, 0, 0.2);
  box-shadow: 20px 20px 36px rgba(0, 0, 0, 0.2);
}

.infrastructure-carousel .ombre-interne {
  background-color: white;
  -webkit-box-shadow: inset 8px 0px 14px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 8px 0px 14px -1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 8px 0px 14px -1px rgba(0, 0, 0, 0.4);
}

.infrastructure-carousel h5 {
  font-size: 4vw;
  font-family: "Caveat", cursive;
  text-shadow: 0px 0px 8px rgba(0, 2, 0, 1);
}

.infrastructure-carousel p {
  text-shadow: -2px -2px 21px rgba(0, 2, 0, 1);
}

@media (max-width: 768px) {
  .infrastructure-img {
    height: 350px;
  }
}
