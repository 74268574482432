.promo + .section::before {
  display: none;
}

button.naked_btn {
  border: none;
  outline: none;
}

.footer-socials.join-us-socials {
  color: #354463;
  margin: 0;
}

.join-us-modal-footer {
  justify-content: space-between;
}

.about_us_wrapper {
  padding: 50px;
}

.about-us--blue.about_us_wrapper::before {
  width: 0;
}

.heading__pre-title.custom_pre-title {
  color: #ffac00;
}
