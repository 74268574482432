.new {
  background: red;
  color: white;
  padding: 5px;
  text-transform: uppercase;
  position: absolute;
  top: -5px;
  left: 15px;
  z-index: 2;
}

.new-tag-mobile {
  background: red;
  color: white;
  padding: 5px;
  text-transform: uppercase;
}

.event-gallery-title-section{
  margin: 0 auto;
  text-align: center;
}